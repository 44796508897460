/* eslint-disable global-require */
import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/composables/notification/useAlert'
import { paginateArray } from '@/helpers/utils'
import useSweetAlertNotification from '@/composables/notification/useSweetAlert'
import router from '@/router'

export default function useProprietes() {
  const { successToast } = useAlertNotification()
  const { errorSweetAlert } = useSweetAlertNotification()

  const propriete = ref([])
  const total = ref(null)
  const loader = ref(false)
  const proprieteProcess = ref(false)
  const contratProcess = ref(false)
  const contratSuccess = ref(false)
  const proprieteSuccess = ref(false)
  const categoryFraisProcess = ref(false)
  const categoryFraisSuccess = ref(false)

  const proprietes = ref([])
  const fraisCategories = ref([])
  const listeContributions = ref([])
  const proprietesDataPaginate = ref([])
  const contractTypes = ref([])
  const factureContratSimples = ref([])
  const demande = ref([])
  const contratsSimples = ref([])
  const errors = ref('')

  const getProprietesByCopropriete = async coproprieteId => {
    try {
      loader.value = true
      const response = await axiosClient.get(`get-all-proprietes-by-copropriete/${coproprieteId}`)
      if (response.data.success === true) {
        loader.value = false
        proprietes.value = response.data.data

        proprietesDataPaginate.value = proprietes.value
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getProprietesSuspenduByCopropriete = async coproprieteId => {
    try {
      loader.value = true
      const response = await axiosClient.get(`get-liste-suspendre-proprietes/${coproprieteId}`)
      if (response.data.success === true) {
        loader.value = false
        proprietes.value = response.data.data

        proprietesDataPaginate.value = proprietes.value
      }
    } catch (error) {
      loader.value = false
    }
  }
  const handleLocationHider = async proprieteId => {
    try {
      loader.value = true
      const response = await axiosClient.patch(`hide-location-propriete/${proprieteId}`)
      if (response.data.success === true) {
        loader.value = false
        proprietes.value = response.data.data

        proprietesDataPaginate.value = proprietes.value
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getProprietaireAllProprietesInCopropriete = async (coproprieteId, proprietaireId) => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-all-proprietes-by-coproprietaire-in-copropriete/${coproprieteId}/${proprietaireId}`)
      if (response.data.success === true) {
        loader.value = false
        proprietes.value = response.data.data

        proprietesDataPaginate.value = proprietes.value
      }
    } catch (error) {
      loader.value = false
    }
  }

  const fetchProprietesByCoproprietaire = async (queryFilter, coproprieteId, proprietaireId) => {
    await getProprietaireAllProprietesInCopropriete(coproprieteId, proprietaireId)
    const queryLowered = queryFilter.q.toLowerCase()
    const filteredData = proprietesDataPaginate.value.filter(item => item.name.toLowerCase().includes(queryLowered))

    const paginatedData = JSON.parse(JSON.stringify(paginateArray(filteredData, queryFilter.perPage, queryFilter.page)))
    proprietesDataPaginate.value = paginatedData
    total.value = filteredData.length
  }
  const fetchProprietes = async (queryFilter, coproprieteId) => {
    await getProprietesByCopropriete(coproprieteId)
    const queryLowered = queryFilter.q.toLowerCase()
    const filteredData = proprietesDataPaginate.value.filter(item => item.name.toLowerCase().includes(queryLowered))

    const paginatedData = JSON.parse(JSON.stringify(paginateArray(filteredData, queryFilter.perPage, queryFilter.page)))
    proprietesDataPaginate.value = paginatedData
    total.value = filteredData.length
  }

  const fetchProprietesSuspendu = async (queryFilter, coproprieteId) => {
    await getProprietesSuspenduByCopropriete(coproprieteId)
    const queryLowered = queryFilter.q.toLowerCase()
    const filteredData = proprietesDataPaginate.value.filter(item => item.name.toLowerCase().includes(queryLowered))

    const paginatedData = JSON.parse(JSON.stringify(paginateArray(filteredData, queryFilter.perPage, queryFilter.page)))
    proprietesDataPaginate.value = paginatedData
    total.value = filteredData.length
  }

  const getProprieteById = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-propriete-by-id/${id}`)
      if (response.data.success === true) {
        propriete.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }

  const createPropriete = async data => {
    try {
      errors.value = ''
      proprieteProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-propriete', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        proprieteSuccess.value = true
        proprieteProcess.value = false
        propriete.value = response.data.data
        await getProprietesByCopropriete()
      }
    } catch (error) {
      proprieteProcess.value = false
      proprieteSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const updatePropriete = async data => {
    try {
      errors.value = ''
      proprieteProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-propriete/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)

        proprieteSuccess.value = true
        proprieteProcess.value = false
        propriete.value = response.data.data
        await getProprietesByCopropriete()
      }
    } catch (error) {
      proprieteProcess.value = false
      proprieteSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const deletePropriete = async id => {
    try {
      errors.value = ''
      proprieteProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.delete(`/delete-propriete/${id}`)
      if (response.data.success === true) {
        successToast('Suppression', response.data.message)

        proprieteSuccess.value = true
        proprieteProcess.value = false
        propriete.value = response.data.data
        await getProprietesByCopropriete()
      }
    } catch (error) {
      proprieteProcess.value = false
      proprieteSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const suspendPropriete = async id => {
    try {
      errors.value = ''
      proprieteProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.get(`/susprendre-propriete/${id}`)
      if (response.data.success === true) {
        successToast('Suspension', response.data.message)

        proprieteSuccess.value = true
        proprieteProcess.value = false
        propriete.value = response.data.data
        await getProprietesByCopropriete()
      }
    } catch (error) {
      proprieteProcess.value = false
      proprieteSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  const publierPropriete = async id => {
    try {
      errors.value = ''
      const response = await axiosClient.patch(`/publish-property/${id}`)
      if (response.data.success === true) {
        if (response.data.message === 'Propriété publiée avec succès') {
          successToast('Rejet de propriété', response.data.message)
        } else {
          successToast('Acceptation de propriété', response.data.message)
        }
        loader.value = false
      }
    } catch (error) {
      loader.value = false
      errors.value = ''
      errorSweetAlert('Oups! Erreur', error.response.data.message)
    }
  }

  const getContractTypes = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-type-contrats')
      if (response.data.success === true) {
        loader.value = false
        contractTypes.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getContractSimple = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-contrat-simples')
      if (response.data.success === true) {
        loader.value = false
        contratsSimples.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getContractsAchatVente = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-contrat-achat-ventes')
      if (response.data.success === true) {
        loader.value = false
        contratsSimples.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  const getDemandeLogementById = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-demande-logement-by-id/${id}`)
      if (response.data.success === true) {
        demande.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }

  const createContractSimple = async data => {
    try {
      errors.value = ''
      contratProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-contrat-simple', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        proprieteSuccess.value = true
        contratProcess.value = false
        // await getProprietesByCopropriete()
        await router.push({ name: 'espace-coproprietaire.contrats', replace: true })
      }
    } catch (error) {
      contratProcess.value = false
      contratSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const updateContractSimple = async data => {
    try {
      errors.value = ''
      contratProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-contrat-simple/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)
        proprieteSuccess.value = true
        contratProcess.value = false
        // await getProprietesByCopropriete()
        await router.push({ name: 'espace-coproprietaire.contrats', replace: true })
      }
    } catch (error) {
      contratProcess.value = false
      contratSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  const changeContratStatus = async (id, data) => {
    try {
      errors.value = ''
      contratProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/change-status-contrat/${id}`, data)
      if (response.data.success === true) {
        successToast('Acceptation', response.data.message)

        proprieteSuccess.value = true
        contratProcess.value = false
      }
    } catch (error) {
      contratProcess.value = false
      proprieteSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const askAuthorizationContrat = async (id, data) => {
    try {
      errors.value = ''
      contratProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/ask-authorization-contrat/${id}`, data)
      if (response.data.success === true) {
        successToast('Acceptation', response.data.message)
        contratProcess.value = false
      }
    } catch (error) {
      contratProcess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const giveAuthorizationContrat = async (id, data) => {
    try {
      errors.value = ''
      contratProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/authorization-contrat/${id}`, data)
      if (response.data.success === true) {
        successToast('Acceptation', response.data.message)
        contratProcess.value = false
      }
    } catch (error) {
      contratProcess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  const createContractAchatVente = async data => {
    try {
      errors.value = ''
      contratProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-contrat-achat-vente', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        proprieteSuccess.value = true
        contratProcess.value = false
        // await getProprietesByCopropriete()
        await router.push({ name: 'espace-coproprietaire.contrats-achat-vente', replace: true })
      }
    } catch (error) {
      contratProcess.value = false
      contratSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const updateContractAchatVente = async data => {
    try {
      errors.value = ''
      contratProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-contrat-achat-vente/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        proprieteSuccess.value = true
        contratProcess.value = false
        // await getProprietesByCopropriete()
        await router.push({ name: 'espace-coproprietaire.contrats-achat-vente', replace: true })
      }
    } catch (error) {
      contratProcess.value = false
      contratSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const getContractByLocataireId = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-all-contrats-by-locataire/${id}`)
      if (response.data.success === true) {
        contratsSimples.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getContractById = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-contrat-simple-byid/${id}`)
      if (response.data.success === true) {
        contratsSimples.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }
  const payerLoyer = async data => {
    try {
      loader.value = true
      const response = await axiosClient.post('/pay-loyer', data)
      if (response.data.success === true) {
        window.location.href = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getContractAchatVenteById = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-contrat-achat-vente-byid/${id}`)
      if (response.data.success === true) {
        contratsSimples.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }
  // gestion payement
  const getFactureByLocataireId = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-locataire-facture-loyer/${id}`)
      if (response.data.success === true) {
        factureContratSimples.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getFraisByProprietaireId = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-proprietaire-facture-loyer/${id}`)
      if (response.data.success === true) {
        factureContratSimples.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getFactureByProprietaireId = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-proprietaire-facture-contribution/${id}`)
      if (response.data.success === true) {
        factureContratSimples.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getFactureCoproprieteByLocataireId = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-locataire-facture-contribution/${id}`)
      if (response.data.success === true) {
        factureContratSimples.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }
  
  const reglerFactureLoyerOfLocataire = async id => {
    try {
      errors.value = ''
      loader.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/regler-facture-de-loyer/${id}`)
      if (response.data.success === true) {
        successToast('Règlement', response.data.message)
        loader.value = false
      }
    } catch (error) {
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  
  const createFraisCopropriete = async data => {
    try {
      errors.value = ''
      contratProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-contribution', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        proprieteSuccess.value = true
        contratProcess.value = false
        // await getProprietesByCopropriete()
      }
    } catch (error) {
      contratProcess.value = false
      contratSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  const payerFraisCopropriete = async data => {
    try {
      loader.value = true
      const response = await axiosClient.post('/pay-contribution', data)
      if (response.data.success === true) {
        window.location.href = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }
  const modifyFraisCopropriete = async data => {
    try {
      errors.value = ''
      contratProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-contribution/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        proprieteSuccess.value = true
        contratProcess.value = false
        // await getProprietesByCopropriete()
      }
    } catch (error) {
      contratProcess.value = false
      contratSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  const getAllCategorieFraisCopropriete = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-categorie-frais')
      if (response.data.success === true) {
        loader.value = false
        fraisCategories.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getAllFraisByCopropriete = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-contributions-by-copropriete/${id}`)
      if (response.data.success === true) {
        loader.value = false
        listeContributions.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  const handleCategoryFrais = async data => {
    try {
      errors.value = ''
      categoryFraisProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-categorie-frais', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        categoryFraisSuccess.value = true
        categoryFraisProcess.value = false
        await getAllCategorieFraisCopropriete()
      }
    } catch (error) {
      categoryFraisProcess.value = false
      categoryFraisSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  const handleUpdateCategoryFrais = async data => {
    try {
      errors.value = ''
      categoryFraisProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-categorie-frais/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        categoryFraisSuccess.value = true
        categoryFraisProcess.value = false
        await getAllCategorieFraisCopropriete()
      }
    } catch (error) {
      categoryFraisProcess.value = false
      categoryFraisSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  return {
    proprietesDataPaginate,
    fetchProprietes,
    payerLoyer,
    total,
    errors,
    propriete,
    proprietes,
    proprieteProcess,
    loader,
    proprieteSuccess,
    getProprietesByCopropriete,
    getProprieteById,
    createPropriete,
    updatePropriete,
    deletePropriete,
    fetchProprietesByCoproprietaire,
    getProprietaireAllProprietesInCopropriete,
    publierPropriete,
    suspendPropriete,
    fetchProprietesSuspendu,
    handleLocationHider,
    getContractTypes,
    contractTypes,
    getDemandeLogementById,
    demande,
    createContractSimple,
    getContractSimple,
    contratsSimples,
    createContractAchatVente,
    getContractsAchatVente,
    changeContratStatus,
    getContractByLocataireId,
    getContractById,
    getContractAchatVenteById,
    updateContractSimple,
    updateContractAchatVente,
    askAuthorizationContrat,
    giveAuthorizationContrat,
    getFactureByLocataireId,
    factureContratSimples,
    getFraisByProprietaireId,
    getFactureByProprietaireId,
    reglerFactureLoyerOfLocataire,
    contratProcess,
    createFraisCopropriete,
    getAllCategorieFraisCopropriete,
    fraisCategories,
    getAllFraisByCopropriete,
    listeContributions,
    modifyFraisCopropriete,
    payerFraisCopropriete,
    getFactureCoproprieteByLocataireId,
    handleCategoryFrais,
    categoryFraisProcess,
    categoryFraisSuccess,
    handleUpdateCategoryFrais,
  }
}
